<template>
  <div class="header mb-32">
    <div class="header-left d-flex align-items-center">
      <div v-if="showBtnBack" class="d-flex">
        <a
          class="btn-variant"
          role="button"
          v-if="variant == 'btn-back'"
          @click="$router.push({ name: parent })"
        >
          <img src="./../../assets/img/icons/24/ic24-chevron-left.svg" alt="" />
        </a>
      </div>
      <span class="t40-52 fw-600">{{ header }}</span>
    </div>
    <div class="header-right d-flex align-items-center">
      <b-dropdown
        class="mr-2"
        variant="link"
        toggle-class="text-decoration-none align-self-center d-flex"
        dropright
        no-caret
      >
        <template #button-content>
          <img
            v-if="true"
            :src="'data:image/png;base64,' + user?.avatar"
            class="user-avatar"
          />
          <img v-else :src="imageUrl('test-user-2.png')" class="user-avatar" />
        </template>
        <b-dropdown-item-button @click="logout">Выход</b-dropdown-item-button>
      </b-dropdown>
      <router-link v-if="addBtn.show" :to="addBtn.path" class="btn-add">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 4.5C12.2761 4.5 12.5 4.72386 12.5 5V11.5H19C19.2761 11.5 19.5 11.7239 19.5 12C19.5 12.2761 19.2761 12.5 19 12.5H12.5V19C12.5 19.2761 12.2761 19.5 12 19.5C11.7239 19.5 11.5 19.2761 11.5 19V12.5H5C4.72386 12.5 4.5 12.2761 4.5 12C4.5 11.7239 4.72386 11.5 5 11.5H11.5V5C11.5 4.72386 11.7239 4.5 12 4.5Z"
            fill="white"
          />
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { authAPI } from "@/api/auth";

const props = defineProps({
  variant: {
    type: String,
    default: "btn-back",
  },
});

const route = useRoute();
const router = useRouter();
const store = useStore();

const showBtnBack = ref(true);
const addBtn = ref(route.meta.addBtn || { show: false, path: "" });
const parent = ref(route.meta.parent || "dashboard");

const logout = async () => {
  try {
    // Вызываем API для выхода пользователя
    await authAPI.logout();

    // Очищаем данные пользователя в хранилище Vuex
    await store.dispatch("auth_logout");

    // Очищаем локальное хранилище
    localStorage.clear();

    // Принудительно переходим на страницу логина без ожидания завершения текущего маршрута
    await router.replace({ name: "login" });

    // После перехода обновляем текущую страницу, чтобы сбросить состояние приложения
    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};

watch(
  () => route.path,
  () => {
    store.dispatch("setHeader", route.meta.title);
    addBtn.value = route.meta.addBtn || { show: false, path: "" };
    parent.value = route.meta.parent || "dashboard";
    showBtnBack.value = route.name !== "dashboard";
  },
  { immediate: true }
);
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  //padding-bottom: 32px;

  .btn-variant {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid getColor(grayscale, gray-0);
    border-radius: 50px;
    margin-right: 24px;
  }
  .btn-add {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
    color: white;
    background-color: getColor(primary, primary-50);
  }
  .user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .header-left {
      display: flex;
      // flex-direction: column;
    }

    .header-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .btn-variant,
    .btn-add,
    .user-avatar {
      width: 40px;
      height: 40px;
      margin-right: 0;
      margin-bottom: 8px;
    }

    .btn-add {
      font-size: 18px;
    }

    .user-avatar {
      width: 32px;
      height: 32px;
    }
  }

  @media (max-width: 480px) {
    .t40-52 {
      font-size: 36px;
      margin-bottom: 12px;
    }

    .header-left {
      display: flex;
      gap: 5px;
      // flex-direction: column;
    }

    .header-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      // margin-top: 12px;
    }

    .btn-variant,
    .btn-add,
    .user-avatar {
      width: 36px;
      height: 36px;
      margin-right: 0;
      margin-bottom: 8px;
    }

    .btn-add {
      font-size: 16px;
    }

    .user-avatar {
      width: 28px;
      height: 28px;
    }
  }
}
</style>
